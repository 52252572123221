import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Stepone from '../SBI/component/StepOne/Stepone';
import StepTwoAccountant from '../SBI/component/StepTwo/StepTwoAccountant';
import StepTwoEletrician from '../SBI/component/StepTwo/StepTwoEletrician';
import StepTwoPlumber from '../SBI/component/StepTwo/StepTwoPlumber';
import Accounted from '../SBI/component/StepThree/Accounted';
import Home from '../SBI/component/Home';
import Spinner from '../SBI/component/Spinner';
import StepFour from '../SBI/component/StepFour';
import Success from '../SBI/component/Success';
import StepFive from '../SBI/component/StepFive';
import PaymentSpinner from '../SBI/component/StepFive/PaymentSpinner';
import CustomerPortal from '../SBI/component/CustomerPortal';
import Login from '../SBI/component/Auth/Login';
import CreateAccount from '../SBI/component/Auth/CreateAccount';

const MainRouter = () => (
  <Router>
    <Routes>
      <Route exact path='/sbi' element={<Home />} />
      <Route exact path='/sbi/step-one' element={<Stepone />} />
      <Route exact path='/sbi/step-two-accountant' element={<StepTwoAccountant />} />
      <Route exact path='/sbi/step-two-electrition' element={<StepTwoEletrician />} />
      <Route exact path='/sbi/step-two-plumber' element={<StepTwoPlumber />} />
      <Route exact path='/sbi/step-three' element={<Accounted />} />
      <Route exact path='/sbi/step-four' element={<StepFour />} />
      <Route exact path='/sbi/loading' element={<Spinner />} />
      <Route exact path='/sbi/success' element={<Success />} />
      <Route exact path='/sbi/step-five' element={<StepFive />} />      
      <Route exact path='/sbi/customer-portal' element={<CustomerPortal />}/>
      <Route exact path='/sbi/payment-spinner' element={<PaymentSpinner />}/>
      <Route exact path='/sbi/login' element={<Login />}/>
      <Route exact path='/sbi/sign-up' element={<CreateAccount />}/>
      <Route
        path='*'
        element={
        <>
          <main style={{ padding: '1rem', textAlign:'center' }}>
            <p>Looks like you are lost :( 
            </p>
              <Link style={{ display: 'block', margin: '1rem 0' }} to={`/`} key={1}>
            Lets go Home
          </Link>
          </main>
        </>
        }
      />
    </Routes >
  </Router>
)

export default MainRouter;
